const Network = ({isWhite=false}) => {

  const color = isWhite ? "#fff" : "#000000"
  const lineColor = isWhite ? "rgb(52, 58, 64)" : "rgb(242,242,242)"

  return (
    <div>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" xmlSpace="preserve">
      <path fill={color} d="M29,9.64c-1.179,0-2.159,0.869-2.333,2h-2.334c-0.156-1.014-0.959-1.817-1.973-1.973V5.333
        c1.131-0.174,2-1.154,2-2.333c0-1.301-1.059-2.36-2.36-2.36S19.64,1.699,19.64,3c0,1.179,0.869,2.159,2,2.333v4.335
        c-0.644,0.099-1.202,0.459-1.564,0.968l-1.828-0.914C18.32,9.494,18.36,9.251,18.36,9c0-1.301-1.059-2.36-2.36-2.36
        c-0.793,0-1.496,0.393-1.924,0.995l-1.828-0.914C12.32,6.494,12.36,6.251,12.36,6c0-1.301-1.059-2.36-2.36-2.36S7.64,4.699,7.64,6
        S8.699,8.36,10,8.36c0.793,0,1.497-0.394,1.924-0.995l1.829,0.914C13.68,8.506,13.64,8.749,13.64,9s0.04,0.494,0.113,0.722
        l-1.828,0.914C11.497,10.034,10.793,9.64,10,9.64c-1.301,0-2.36,1.059-2.36,2.36c0,1.179,0.869,2.159,2,2.333v3.344
        c-1.012,0.157-1.807,0.952-1.964,1.964H5.333c-0.174-1.131-1.154-2-2.333-2c-1.301,0-2.36,1.059-2.36,2.36S1.699,22.36,3,22.36
        c1.179,0,2.159-0.869,2.333-2h2.344c0.157,1.012,0.952,1.807,1.964,1.964v4.343c-1.131,0.174-2,1.154-2,2.333
        c0,1.302,1.059,2.36,2.36,2.36s2.36-1.059,2.36-2.36c0-1.179-0.869-2.159-2-2.333v-4.343c0.642-0.099,1.199-0.453,1.56-0.961
        l1.833,0.916C13.68,22.506,13.64,22.749,13.64,23c0,1.302,1.059,2.36,2.36,2.36c0.793,0,1.496-0.394,1.925-0.996l1.828,0.915
        C19.68,25.507,19.64,25.749,19.64,26c0,1.302,1.059,2.36,2.36,2.36s2.36-1.059,2.36-2.36s-1.059-2.36-2.36-2.36
        c-0.793,0-1.496,0.394-1.925,0.996l-1.828-0.914C18.32,23.494,18.36,23.251,18.36,23s-0.04-0.494-0.113-0.721l1.828-0.915
        c0.429,0.603,1.132,0.996,1.925,0.996c1.302,0,2.36-1.059,2.36-2.36c0-1.179-0.869-2.159-2-2.333v-3.334
        c1.014-0.156,1.816-0.959,1.973-1.973h2.334c0.174,1.131,1.154,2,2.333,2c1.302,0,2.36-1.059,2.36-2.36S30.302,9.64,29,9.64z
         M10,7.64C9.096,7.64,8.36,6.904,8.36,6S9.096,4.36,10,4.36S11.64,5.096,11.64,6S10.904,7.64,10,7.64z M3,21.64
        c-0.904,0-1.64-0.735-1.64-1.64S2.096,18.36,3,18.36S4.64,19.096,4.64,20S3.904,21.64,3,21.64z M22,24.36
        c0.904,0,1.64,0.735,1.64,1.64s-0.735,1.64-1.64,1.64s-1.64-0.735-1.64-1.64S21.096,24.36,22,24.36z M20.36,3
        c0-0.904,0.735-1.64,1.64-1.64S23.64,2.096,23.64,3S22.904,4.64,22,4.64S20.36,3.904,20.36,3z M16,7.36
        c0.904,0,1.64,0.736,1.64,1.64s-0.735,1.64-1.64,1.64S14.36,9.904,14.36,9S15.096,7.36,16,7.36z M17.64,16
        c0,0.904-0.735,1.64-1.64,1.64s-1.64-0.735-1.64-1.64s0.736-1.64,1.64-1.64S17.64,15.096,17.64,16z M12.36,12
        c0-0.251-0.04-0.494-0.112-0.721l1.829-0.914c0.362,0.509,0.92,0.869,1.564,0.967v2.335c-1.131,0.174-2,1.154-2,2.333
        c0,0.355,0.079,0.692,0.22,0.995l-2.126,1.417c-0.354-0.387-0.832-0.652-1.374-0.736v-3.344C11.491,14.159,12.36,13.179,12.36,12z
         M8.36,12c0-0.904,0.736-1.64,1.64-1.64s1.64,0.736,1.64,1.64s-0.736,1.64-1.64,1.64S8.36,12.904,8.36,12z M11.64,29
        c0,0.904-0.736,1.64-1.64,1.64S8.36,29.904,8.36,29s0.736-1.64,1.64-1.64S11.64,28.096,11.64,29z M10,21.64
        c-0.904,0-1.64-0.735-1.64-1.64s0.736-1.64,1.64-1.64s1.64,0.735,1.64,1.64S10.904,21.64,10,21.64z M16,24.64
        c-0.904,0-1.64-0.735-1.64-1.64s0.736-1.64,1.64-1.64c0.904,0,1.64,0.735,1.64,1.64S16.904,24.64,16,24.64z M19.753,20.721
        l-1.828,0.915C17.496,21.033,16.793,20.64,16,20.64c-0.793,0-1.496,0.394-1.924,0.995l-1.839-0.919
        C12.31,20.489,12.36,20.251,12.36,20c0-0.355-0.084-0.688-0.225-0.99l2.125-1.417c0.432,0.472,1.052,0.768,1.74,0.768
        c0.688,0,1.309-0.297,1.74-0.768l2.119,1.413c-0.141,0.302-0.22,0.64-0.22,0.994C19.64,20.251,19.68,20.494,19.753,20.721z
         M18.141,16.994c0.141-0.302,0.22-0.639,0.22-0.994c0-1.179-0.869-2.159-2-2.333v-2.335c0.644-0.099,1.203-0.459,1.565-0.968
        l1.828,0.914C19.68,11.506,19.64,11.749,19.64,12c0,1.179,0.869,2.159,2,2.333v3.334c-0.542,0.084-1.024,0.353-1.38,0.74
        L18.141,16.994z M23.64,20c0,0.904-0.735,1.64-1.64,1.64s-1.64-0.735-1.64-1.64s0.735-1.64,1.64-1.64S23.64,19.096,23.64,20z
         M22,13.64c-0.904,0-1.64-0.736-1.64-1.64s0.735-1.64,1.64-1.64s1.64,0.736,1.64,1.64S22.904,13.64,22,13.64z M29,13.64
        c-0.904,0-1.64-0.736-1.64-1.64s0.735-1.64,1.64-1.64s1.64,0.736,1.64,1.64S29.904,13.64,29,13.64z"/>
      </svg>
    </div>
  )
}


export default Network
