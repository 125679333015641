import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { SendData } from "../../../../functions/SendData";
import Loading from "../../../../elements/Loading";
import ItemData from "./itemData";
import '../styles.css'

export default function ProjectItem(){

    const id = useLocation().pathname.split('/')[2];
    const [data, setData] = useState('')
    const [loading, setLoading] = useState('')
    const navigate = useNavigate()

    const getData = async () => {

        setLoading(true)

        await SendData('get', 'project', 'getItem', { id: id }).then(res => {

            setData(res.data)
            setLoading(false)

        })


    }

    useEffect( () => {

        getData()

    }, [] )

    return(
        
            loading ?
                <Loading />
                :
                <div className="content projects">
                
                    <ItemData data={data} />

                    <div className="watchMore buttons black mt-2">
                        <button onClick={() => navigate(-1)}>
                            Назад
                            <span></span>
                        </button>
                    </div>

                </div>
        

    )

}