import { useState } from "react";
import { SendData } from "../../../../functions/SendData";
import Loading from "../../../../elements/Loading";
import { useNavigate } from "react-router-dom";


export default function NewProject() {

    const [load, setLoad] = useState(false)
    const [title, setTitle] = useState('')
    const [heigth, setHeigth] = useState('')
    const [area, setArea] = useState('')
    const [rooms, setRooms] = useState('')
    const [walls, setWalls] = useState('1')
    const [result, setResult] = useState('')
    const [isCreated, toggleCreated] = useState(false)
    const navigate = useNavigate()    

    const send = async () => {

        setLoad(true)

        const data = { name: title, params: JSON.stringify({ heigth: heigth, area: area, rooms: rooms, walls: walls }) }

        SendData('post', 'project', 'add', data).then(res => {

            if (res.data.result === 'success') {

                toggleCreated(true)

            }

            else {
                setResult(res.data.result)
            }

            setLoad(false)

        })

    }



    return (

        load ?
            <Loading />
            :
            <div className="content wrap">

                <div className='f-row ai-center jc-sb'>

                    <h1>Новый проект</h1>

                </div>

                {isCreated ?

                    <div><h2>Проект успешно создан</h2></div>

                    :

                    <div className="form">

                        <div className="field f-col mb-2">
                            <label htmlFor="title">Наименование</label>
                            <input type="text" name="title" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>

                        <div className="field f-col mb-2">
                            <label htmlFor="heigth">Высота потолка, мм</label>
                            <input type="number" name="heigth" value={heigth} onChange={(e) => setHeigth(e.target.value)} />
                        </div>

                        <div className="field f-col mb-2">
                            <label htmlFor="area">Общая площадь м<sup>2</sup></label>
                            <input type="number" name="area" value={area} onChange={(e) => setArea(e.target.value)} />
                        </div>

                        <div className="field f-col mb-2">
                            <label htmlFor="rooms">Количество помещений</label>
                            <input type="number" name="rooms" value={rooms} onChange={(e) => setRooms(e.target.value)} />
                        </div>

                        <div className="field f-col mb-2">
                            <label htmlFor="walls">Материал стен</label>
                            <select value={walls} name="walls" onChange={(e) => setWalls(e.target.value)}>
                                <option value="3">Бетон</option>
                                <option value="2">Кирпич</option>
                                <option value="1">Газоблок</option>
                            </select>
                        </div>

                        <div className="formResult mb-2">
                            {result}
                        </div>

                        <div className="field formButton">
                            <button onClick={() => send()}>Создать</button>
                        </div>

                    </div>

                }

                <div className="watchMore buttons black mt-2">
                    <button onClick={() => isCreated ? navigate('/projects') : navigate(-1)}>
                        {isCreated ? 'Продолжить' : 'Назад'}
                        <span></span>
                    </button>
                </div>

            </div>

    )



}