import React, {useState } from 'react'
import { DragDropContext, Droppable, Draggable  } from '@hello-pangea/dnd'

const EditView = ({data, main, editList, editMain}) => {


  const items = data.length>0 ? Array.from(JSON.parse(data).data) : null

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    data = JSON.stringify({"data":items})
    editList(data)

  }

  const toggleImage = (index, visible) => {
    items[index].visible = visible
    data = JSON.stringify({"data":items})
    editList(data)
  }

  const deleteImage = (value) => {
    console.log(value)
  }

  return(

    <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters">
          {(provided) => (
            data.length>0 ?
              <table className="editgallery" {...provided.droppableProps} ref={provided.innerRef}>
                <thead>
                  <tr>
                    <th>Изображение</th>
                    <th>Действия</th>
                  </tr>
                </thead>
                <tbody>
                {
                  JSON.parse(data).data.map((item,index) =>
                    <Draggable key={`image ${index}`} draggableId={`image ${index}`} index={index}>
                      {(provided) => (
                        <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <td><img className={item.visible ? null : "hidden"} src={`https://api.nanelectro.ru/files/images/${item.image}`} alt={`Картинка ${index + 1}`} /></td>
                          <td>
                            <div className="actions f-col">
                              {item.image === main ?
                                null
                                :
                                <input type="button" value="Сделать заглавной" onClick={()=>editMain(item.image)} />
                              }
                              <input type="button" value={item.visible ? "Скрыть" : "Опубликовать"} onClick={()=>toggleImage(index, !item.visible)} />
                              <input type="button" value="Удалить" onClick={()=>deleteImage(index)} />
                            </div>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  )
                }
                {provided.placeholder}
                </tbody>
              </table>
              :
              null
          )}
        </Droppable>
    </DragDropContext>

  )
}

export default EditView
