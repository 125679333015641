import React, {useState} from 'react'
import { useLocation,Link } from "react-router-dom"
import Menu from './Menu'
import UserMenu from './UserMenu'
import Logo from '../../media/Logo'
import {isMobile} from 'react-device-detect';
import './TopNav.css'


const TopNav = ({isLanding = false}) => {
  const [showMenu, toggleMenu] = useState(isMobile ? false : true)
  const location = useLocation()
  const {pathname} = location
  const url = pathname.split("/")
  const page = url[1]

  return(

    <nav className="menu">

      <div className="f-row ai-center">
        <Logo isWhite={isLanding ? true : false} />
      </div>

      {
        showMenu ?
          <Menu page={page} mobile={isMobile} landing={isLanding} toggle={() => toggleMenu(!showMenu)} />
          :
          null
      }

      {isMobile ?
        <div className={`menuIcon ${showMenu ? 'open' : ''} ${isLanding ? '' : 'black'}`}>
          <button onClick={() => toggleMenu(!showMenu)}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        :
        <UserMenu isLanding={isLanding} />

      }

    </nav>

  )
}



export default TopNav
