import React, {useState,useEffect} from 'react'
import {SendData} from '../../../functions/SendData'
import './Content.css'
import {isMobile} from 'react-device-detect';
import Article from './Article'
import GalleryItem from './GalleryItem'
import { Link } from "react-router-dom"

const Content = ({type}) => {

  const [data, setData] = useState('')
  const header = (type==='article') ? 'НАШ БЛОГ' : 'НАШИ РАБОТЫ'
  const linkPath = (type==='article') ? '/articles' : '/cases'
  const linkTitle = (type==='article') ? 'Все материалы' : 'Смотреть ещё'
  const getContent = async() => {
    SendData('get','content','getmain', {type: type}).then(result => setData(result.data))
  }

  useEffect(() => {

    getContent()

  }, [])

  return(
    <div className={`${(type==='article') ? 'main__articles' : 'main__cases landing'} f-col`}>

      <h1>{header}</h1>

      <div className={`wrap ${isMobile ? 'f-col ai-center' : 'f-row'}`}>

      {data ?
        type==='article' ?
          <Article data={data} />
          :
          <GalleryItem data={data} isLanding={true} />
        :
        null
      }


      </div>

      <div className="watchMore buttons black">
        <Link to={linkPath}>
          {linkTitle}
          <span></span>
        </Link>
      </div>

    </div>
  )
}



export default Content
