import React,{useState,useEffect} from 'react'
import {SendData, LoadFile} from '../../../functions/SendData'
import Loading from '../../../elements/Loading'
import { useLocation, useNavigate } from "react-router-dom"
import {useSelector} from 'react-redux'
import Add from '../../../media/Add'
import Edit from '../../../media/Edit'
import ItemView from './ItemView'
import EditView from './EditView'
import { Helmet } from 'react-helmet-async'

const initialList = {
  title: '',
  path:'',
  main:'',
  data:''
}


const Case = _ => {
  const isAdmin = useSelector(state => state.user[0].type) == 'admin'
  const [onEdit,setEdit] = useState(false)
  const [isLoad,setIsLoad] = useState(true)
  const [title,setTitle] = useState('')
  const [label,setLabel] = useState('')
  const [main, setMain] = useState('')
  const [list, setList] = useState(initialList)
  const [published, setPubslished] = useState('')
  const id = useLocation().pathname.split('/')[2];
  const navigate = useNavigate()

  const getCase = async(currentId=false) => {

    setIsLoad(true)

    const data = {id: currentId ? currentId :id, type: 'gallery'}

    SendData('get','content', 'getitem', data).then(result => {
      setTitle(result.data.title)
      setLabel(result.data.title)
      setMain(result.data.main)
      setList(result.data.data)
      setPubslished(result.data.published)
      setIsLoad(false)
    })

  }

  const togglePublished = async() => {
    setIsLoad(true)

    const data = {id: id, type: 'gallery', published: !published }
    await SendData('post', 'content', 'publication', data).then(result => {
      setPubslished(!published)
      console.log(result.data)
      setIsLoad(false)
    })
  }

  const apply = async() => {

    setIsLoad(true)

    const data = {id: id, type: 'gallery', main: main, title: title, data: list}
    const action = (id === 'new') ? 'add' : 'update'

    await SendData('post', 'content', action, data).then(result => {

      if(result.data.result === 'success'){

        setLabel(title)
        setEdit(false)

        if(id === 'new'){
          navigate(`/cases/${result.data.id}`)
        }

      }

      setIsLoad(false)


    })

  }

  const fileAdd = async(e) => {

    e.preventDefault()

    setIsLoad(true)

    const data = {id: id, title: title}

    await LoadFile(data, e.target.files[0]).then(result => {

      let newId = id

      if(id === 'new'){
        newId = result.data.id
        navigate(`/cases/${newId}`)
      }

      getCase(newId)

    })

  }

  useEffect(() => {

    window.scrollTo(0, 0)

    if(id !== 'new'){
      getCase()
    }

    else{
      setLabel('Новый материал')
      setEdit(true)
      setIsLoad(false)
    }

  }, [])


  return(

    isLoad ?
      <Loading />
      :
      <div className="content">

        <Helmet prioritizeSeoTags>
          <title>NAN-electro | {label}</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>

        <div className="title f-row ai-center">
           <h1>
            <div dangerouslySetInnerHTML={{ __html: label.replace('кв. м', 'м<sup>2</sup>') }}></div>
           </h1>
           {
             isAdmin ?
              onEdit ?
                null
                :
                <div className="buttons black"><button className="edit" onClick={() => setEdit(true)}><Edit /><span></span></button></div>
              :
              null
           }
        </div>

        {
          isAdmin ?
            onEdit ?
              <div className="buttons black">
                <button onClick={() => apply()}>Применить<span></span></button>
                <button onClick={() => togglePublished()}>{published ? 'Снять с публикации' : 'Опубликовать'}<span></span></button>
                <button onClick={() => {id === 'new' ? navigate(-1) : setEdit(false)}}>Отмена<span></span></button>
              </div>
              :
              null
            :
            null
        }

        {
          onEdit ?
            <div className="title f-col ai-center">
              <h2>Наименование</h2>
              <textarea value={title} onChange={(e) => setTitle(e.target.value)} />
            </div>
            :
            null

        }

        {
          onEdit ?
            <div className="f-col ai-center">



              <label className="loadfile f-row ai-center">

                <Add />

                <input type="file" name="file" accept=".png, .jpeg, .jpg" onChange={(e) => fileAdd(e)} />
                Добавить

              </label>

            </div>

            :

            null

        }

        {onEdit ?
          <EditView data={list} main={main} editMain={(value) => setMain(value)} editList={(value) => setList(value)} />
          :
          <ItemView data={list} />
        }

        <div className="watchMore buttons black">
          <button onClick={() => navigate(-1)}>
            Назад
            <span></span>
          </button>
        </div>

      </div>

  )
}



export default Case
