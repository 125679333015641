import { useState, useEffect } from "react"
import Loading from "../../../elements/Loading"
import { SendData } from "../../../functions/SendData"
import { Link } from "react-router-dom"
import Add from "../../../media/Add"
import ProjectList from "./list"
import './styles.css'

export default function Projects() {

    const [list, setList] = useState('')
    const [loading, setLoading] = useState(false)

    const getList = async () => {

        setLoading(true)
        await SendData('get', 'project', 'getlist', null).then(res => {

            setList(res.data)
            setLoading(false)

        })
    }

    useEffect(() => {
        getList()
    }, [])

    return (

        loading ?
            <Loading />
            :
            <div className="content projects">

                <div className='f-row ai-center jc-sb'>

                    <h1>Мои проекты</h1>

                    <Link to={`/projects/new`}>
                        <Add />
                    </Link>

                </div>

                {list.length > 0 ?

                    <ProjectList data={list} />

                    :

                    <h5>Вы еще не создавали проекты</h5>

                }

            </div>

    )
}