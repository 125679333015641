import React from 'react'
import AppRouter from './AppRouter'
import {SendData} from './functions/SendData'
import {settype, login} from '../store/actions'
import {connect} from 'react-redux'
import './Main.css'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'

class Main extends React.Component{

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props){
    super(props)
    this.state={
      loading:true
    }
  }

  componentDidMount(){    
    this.checkUser()
  }

  checkUser = async () => {

    this.setState({loading: true})

    const { cookies } = this.props;

    if(JSON.stringify(cookies.get('token'))){

      SendData('get','user', 'checkUser', false)
        .then((result) => {

          if(result.data.check){
            this.props.setauth()

            if(result.data.res !== 'consumer'){
              this.props.setUserType(result.data.res)
            }

          }

          else{
            cookies.remove('email')
            cookies.remove('token')
          }

        })

    }

    this.setState({loading: false})

  }

  render(){

    

    return(
      <AppRouter />
    )

  }

}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setauth: () => dispatch(login()),
    setUserType: (value) => dispatch(settype(value))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(Main))
