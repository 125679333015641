import React, { useState } from 'react'
import Loading from '../../elements/Loading'
import { Link } from "react-router-dom"
import { SendData } from '../../functions/SendData'
import { useCookies } from 'react-cookie'
import { useDispatch } from 'react-redux'
import { login as storageEnter } from '../../../store/actions'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const Auth = _ => {

  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [result, setResult] = useState('')
  const [loading, setLoading] = useState(false)
  const [cookies, setCookie] = useCookies()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const send = async () => {

    setLoading(true)

    const data = { login: login, password: password }

    await SendData('post', 'user', 'login', data).then(result => {

      if (result.data.auth === 'error') {
        let text

        switch (result.data.status) {
          case 'wrong_password':
            text = 'Неверно указан пароль'
            break;
          case 'not_registred':
            text = 'Пользователь не зарегистрирован'
            break;
          default:
            break
        }

        setResult(text)

      }
      else {
        setCookie('user', login, { path: '/' })
        setCookie('token', result.data.token, { path: '/' })
        dispatch(storageEnter())
        navigate("/")
        window.location.reload()
      }

      setLoading(false)

    })


  }

  return (

    loading ?
      <Loading />
      :
      <div className="content wrap">

        <Helmet prioritizeSeoTags>
          <title>NAN-electro | Авторизация</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>

        <div className="form">
          <div className="formHeader mb-1">Вход</div>

          <div className="field f-col mb-2">
            <label htmlFor="email">Логин</label>
            <input type="text" name="login" onChange={(e) => setLogin(e.target.value)} />
          </div>

          <div className="field f-col mb-2">
            <div className="f-row jc-sb">
              <label htmlFor="password">Пароль</label>
              {/*<div className="reset-pass">
                <Link to={"/recovery"} >
                  Забыли пароль?
                </Link>
              </div>*/}
            </div>
            <input type="password" name="password" onChange={(e) => setPassword(e.target.value)} />
          </div>

          <div className="formResult mb-2">
            {result}
          </div>

          <div className="field formButton">
            <button onClick={() => send()}>Войти</button>
          </div>

        </div>

        {/*<div className="f-row pt-2">
          <div>Не зарегистрированы?</div>
          <Link to={"/signup"} className="mx-1">
            Регистрация
          </Link>
        </div>*/}



      </div>

  )

}



export default Auth
