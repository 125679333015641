import React from 'react'
import { Outlet } from 'react-router-dom'
import TopNav from '../../elements/topnav/TopNav'
import Footer from '../../elements/Footer'
import './Default.css'

const Default = _ =>

  <div className="page">
    <main>
      <TopNav />
      <Outlet />
    </main>
    <Footer />
  </div>

export default Default
