import React from 'react'
import './Style.css'
import { Helmet } from 'react-helmet-async'

const Contacts = _ =>

  <div className="content contacts">

    <Helmet prioritizeSeoTags>
      <title>NAN-electro | Контакты</title>
      <link rel="canonical" href={window.location.href} />
      <meta name="description" content="Контактная информация нашей компании: телефон, email и регион предоставления услуг. Свяжитесь с нами для получения консультации или заказа."></meta>
    </Helmet>

    <h1>Контакты</h1>

    <div className="row">
      <div className="header">Почта</div>
      <div className="value">info@nanelectro.ru</div></div>
    <div className="row">
      <div className="header">Телефон</div>
      <div className="value">+7 978 019 67 27</div>
    </div>
    <div className="row">
      <div className="header">Регион работы</div>
      <div className="value">Республика Крым и город Севастополь</div>
    </div>

  </div>

export default Contacts
