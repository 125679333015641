import React, {useState,useEffect} from 'react'
import {useSelector} from 'react-redux'
import {SendData} from '../../../functions/SendData'
import Loading from '../../../elements/Loading'
import GalleryItem from '../../landing/content/GalleryItem'
import Add from '../../../media/Add'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import './Style.css'

const Portfolio = _ => {

  const isAdmin = useSelector(state => state.user[0].type) == 'admin'
  const [data, setData] = useState('')
  const [isLoad,setIsLoad] = useState(true)
  const linkPath = '/cases'
  const getContent = async() => {
    SendData('get','content','getList', {type: 'gallery'}).then(result => {
      setData(result.data)
      setIsLoad(false)
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getContent()

  }, [])

  return(
    isLoad ?
      <Loading />
      :
      <div className="content">
        <Helmet prioritizeSeoTags>
          <title>NAN-electro | Наши работы</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content="Портфолио выполненных проектов. Примеры успешных объектов, реализованных нашей компанией в различных отраслях и областях."></meta>
        </Helmet>
        <div className="title f-row ai-center jc-sb">
           <h1>Наши работы</h1>
           {
             isAdmin ?
               <Link to={`/cases/new`}>
                 <Add />
               </Link>
              :
              null
           }
        </div>
        <div className="main__cases">
          <GalleryItem data={data} isAdmin={isAdmin} />
        </div>

      </div>
  )

}


export default Portfolio
