import React from 'react'
import TopNav from '../../elements/topnav/TopNav'
import './Head.css'
//import bg from '../../media/images/bg7.png'

const Head = _ =>

  <div className="head">
    <TopNav isLanding={true} />
    <div className="content">
      <div className="text">
        <div className="title">Профессиональные решения для ваших электрических систем</div>
        <div className="descr">Мы являемся профессиональными специалистами в области электромонтажа, предоставляющими широкий спектр услуг для обеспечения безопасности и надежности электрических систем в вашем доме или офисе. Наш опыт работы и использование передовых технологий обеспечивают высокое качество наших услуг и полную удовлетворенность клиентов.</div>
      </div>

      <div className="image">
      </div>

    </div>




  </div>

export default Head
