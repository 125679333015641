export default function ItemCalculation({ name, list, total }) {
    return (
        <>
            <h3>{name}</h3>
            <table>
                <thead>
                    <tr>
                        <th className='name'>Наименование</th>
                        <th>Количество</th>
                        <th>Цена за единицу</th>
                        <th>Общая цена</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((item, index) =>
                        <tr key={index}>
                            <td className='name'>{item.name}</td>
                            <td>{item.quantity}</td>
                            <td>{item.price}</td>
                            <td>{item.total}</td>
                        </tr>
                    )
                    }
                    <tr>
                        <td colSpan={3}><b>Общая стоимость {name === 'Работы' ? 'работ' : 'материалов'}</b></td>
                        <td><b>{total}</b></td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}