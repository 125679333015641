import React from 'react'
import {Link} from "react-router-dom"
import './Style.css'

const ListItem = ({data}) =>

  <li itemProp="blogPost" itemScope itemType="http://schema.org/BlogPosting">
    <Link to={`/articles/${data.id}`} itemProp="url">
      <h2 itemProp="headline" className={`header`}>{data.title}{data.published==="1" ? '' : ' (не опубликован)'}</h2>
    </Link>
  </li>

export default ListItem
