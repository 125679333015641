import React from 'react'
import {Link} from "react-router-dom"
import './GalleryItem.css'

const FILE_URL = 'https://api.nanelectro.ru/files/images/'

const GalleryItem = ({data, isAdmin=false}) =>

  data.map((item) =>

    <Link to={`/cases/${item.id}`} key={item.id} >
      <div className={`item${isAdmin ? item.published==="1" ? '' : ' hidden' : ''}`}>

        <div className="image">
          <img src={`${FILE_URL}${item.main}`} alt={item.title} />
        </div>

        <div className="description">
          <div dangerouslySetInnerHTML={{ __html: item.title.replace('кв. м', 'м<sup>2</sup>') }}></div>
          {isAdmin ? item.published==="1" ? '' : ' (не опубликован)' : ''}
        </div>
      </div>
    </Link>



  )
export default GalleryItem
