import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Loading from '../../elements/Loading'

const Registration = _ => {

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const send = _ => {
    setLoading(true)
    console.log('send to registration')
    setLoading(false)
  }

  let result

  return(

    loading ?
      <Loading />
      :
      <div className="content wrap">

        <div className="form">

          <div className="formHeader mb-1">Регистрация</div>

          <div className="field f-col mb-2">
            <label htmlFor="login">Логин</label>
            <input type="text" name="login" autoComplete="off" />
          </div>

          <div className="field f-col mb-2">
            <label htmlFor="login">Email</label>
            <input type="email" name="email" autoComplete="off" />
          </div>

          <div className="field f-col mb-2">
            <label htmlFor="login">Пароль</label>
            <input type="password" name="password" autoComplete="off" />
          </div>

          <div className="field f-col mb-2">
            <label htmlFor="login">Повторите пароль</label>
            <input type="password" name="password_reply" autoComplete="off" />
          </div>

          <div className="formResult mb-2">
            {result}
          </div>

          <div className="field formButton w50">
            <button className="default" onClick={() => navigate(-1)}>Назад</button>
            <button onClick={() => send()}>Зарегистрироваться</button>
          </div>

        </div>

      </div>

  )
}

export default Registration
