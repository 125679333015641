import React from 'react'

const ItemView = ({introText,text}) =>

    <div>
      <div className="intro">{introText}</div>
      <div itemProp="articleBody" dangerouslySetInnerHTML={{ __html: text }} />
    </div>

export default ItemView
