import React,{useState} from 'react'
import { useNavigate } from "react-router-dom"
import Loading from '../../elements/Loading'

const Recovery = _ => {

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const send = _ => {
    setLoading(true)
    console.log('send to recovery')
    setLoading(false)
  }

  let result

  return(

    loading ?
      <Loading />
      :
      <div className="content wrap">

        <div className="form">
          <div className="formHeader mb-1">Восстановление доступа</div>
          <div className="field f-col mb-2">
            <label htmlFor="email">Почта</label>
            <input type="email" name="email" placeholder="Email" autoComplete="off" />
          </div>

          <div className="formResult mb-2">
            {result}
          </div>

          <div className="field formButton w50">
            <button className="default" onClick={() => navigate(-1)}>Назад</button>
            <button onClick={() => send()}>Восстановить</button>
          </div>

        </div>

      </div>

  )

}



export default Recovery
