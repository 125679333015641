import React from 'react'
import {Link} from "react-router-dom"

const Article = ({data}) =>

  data.map((item) =>
    <Link to={`/articles/${item.id}`} key={item.id} >
      <div className="header">{item.title}</div>
      <div className="text">{item.introtext}</div>
    </Link>


  )
export default Article
