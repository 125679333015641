export const login = () => {
  return {
    type: 'IS_AUTH'
  }
}

export const logout = () => {
  return {
    type: 'EXIT'
  }
}

export const settype = (data) => {
  return {
    type: 'SETTYPE',
    value: data

  }
}
