import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie'
import User from '../../media/User'
import { logout } from '../../../store/actions'
import './UserMenu.css'


const Menu = ({ page, mobile, landing, toggle }) => {
  const [showMenu, toggleMenu] = useState(false)
  const isAuth = useSelector(state => state.user[0].isLogged)
  const [cookies, setCookie, removeCookie] = useCookies(['user'])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const exit = (e) => {
    e.preventDefault()
    removeCookie('user')
    removeCookie('token')
    dispatch(logout())
    navigate("/")
    window.location.reload()
  }

  return (

    <div className={`${mobile ? 'wrap' : 'f-row ai-center buttons'} ${landing ? '' : 'black'}`}>

      <Link to={"/"} onClick={mobile ? toggle : null}>
        Главная
        <span></span>
      </Link>

      <Link to={"/about"} onClick={mobile ? toggle : null} className={page === 'about' ? 'active' : null}>
        О нас
        <span></span>
      </Link>

      <Link to={"/services"} onClick={mobile ? toggle : null} className={page === 'services' ? 'active' : null}>
        Цены
        <span></span>
      </Link>

      <Link to={"/cases"} onClick={mobile ? toggle : null} className={page === 'cases' ? 'active' : null}>
        Портфолио
        <span></span>
      </Link>

      <Link to={"/articles"} onClick={mobile ? toggle : null} className={page === 'articles' ? 'active' : null}>
        Советы
        <span></span>
      </Link>

      <Link to={"/contacts"} onClick={mobile ? toggle : null} className={page === 'contacts' ? 'active' : null}>
        Контакты
        <span></span>
      </Link>

      {mobile ?
        <div className="user">
          {
            isAuth ?
              <div className="f-col ai-center">
                <div className="username">{cookies.user}</div>
                <Link to={"/projects"} onClick={mobile ? toggle : null}>
                  Мои проекты
                </Link>
                <input type="button" value="Выйти" onClick={(e) => exit(e)} />
              </div>
              :
              <Link to={"/auth"} onClick={mobile ? toggle : null}>
                Вход
              </Link>
          }

        </div>
        :
        null
      }

    </div>

  )
}



export default Menu
