import React from 'react'
import { Link } from "react-router-dom"
import './Services.css'
import {isMobile} from 'react-device-detect';
import one from '../../media/images/1.jpg';
import three from '../../media/images/3.jpg';
import four from '../../media/images/4.jpg';
import five from '../../media/images/5.jpg';
import six from '../../media/images/6.png';
import seven from '../../media/images/7.jpg';
import nine from '../../media/images/9.jpg';
import ten from '../../media/images/10.jpg';

const Services = _ =>

  <div className="services">

    {/*<h1>НАШИ УСЛУГИ</h1>*/}

    <section className="hero-section f-col">

      <div className={`card-grid ${isMobile ? 'f-col' : 'f-row'}`}>

        <div className="card">
          <div className="card__background" style={{backgroundImage: `url(${seven})`}}></div>
          <div className="card__content">
            <h3 className="card__heading f-col"><span>Проектирование</span><span>электропроводки</span></h3>
          </div>
        </div>

        <div className="card">
          <div className="card__background" style={{backgroundImage: `url(${six})`}}></div>
          <div className="card__content">
            <h3 className="card__heading f-col"><span>Монтаж</span><span>электропроводки</span></h3>
          </div>
        </div>

        <div className="card">
          <div className="card__background" style={{backgroundImage: `url(${three})`}}></div>
          <div className="card__content">
            <h3 className="card__heading f-col"><span>Проектирование</span><span>электрощитов</span></h3>
          </div>
        </div>

        <div className="card">
          <div className="card__background" style={{backgroundImage: `url(${one})`}}></div>
          <div className="card__content">
            <h3 className="card__heading f-col"><span>Сборка</span><span>электрощитов</span></h3>
          </div>
        </div>

        <div className="card">
          <div className="card__background" style={{backgroundImage: `url(${nine})`}}></div>
          <div className="card__content">
            <h3 className="card__heading f-col"><span>Установка и обслуживание</span><span>систем освещения</span></h3>
          </div>
        </div>

        <div className="card">
          <div className="card__background" style={{backgroundImage: `url(${ten})`}}></div>
          <div className="card__content">
            <h3 className="card__heading f-col"><span>Наладка</span><span>электрооборудования</span></h3>
          </div>
        </div>

        <div className="card">
          <div className="card__background" style={{backgroundImage: `url(${five})`}}></div>
          <div className="card__content">
            <h3 className="card__heading f-col"><span>Установка</span><span>видеонаблюдения</span></h3>
          </div>
        </div>

        <div className="card">
          <div className="card__background" style={{backgroundImage: `url(${four})`}}></div>
          <div className="card__content">
            <h3 className="card__heading f-col"><span>Системы контроля</span><span>управления доступа</span></h3>
          </div>
        </div>

      </div>

    </section>

    <div className="buttons">
    <Link to={"/services"}>
        Все услуги
        <span></span>
    </Link>
    </div>



  </div>

export default Services
