import React from 'react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet-async'


const About = _ =>

  <div className="content">

    <Helmet prioritizeSeoTags>
      <title>NAN-electro | О нас</title>
      <link rel="canonical" href={window.location.href} />
      <meta name="description" content="Опытная компания по электромонтажу. Качественные услуги для жилых и коммерческих объектов. Надежное оборудование, квалифицированные специалисты, безопасность электрических систем."></meta>
    </Helmet>

    <h1>О нас</h1>
    <p>Наша компания, специализирующаяся на электромонтаже, имеет более чем 15 лет опыта в этой сфере. За это время мы завоевали репутацию надежного и профессионального исполнителя задач, связанных с электрикой и электрооборудованием. Наша команда охватывает высококвалифицированных инженеров и техников, которые работают с последними технологиями и оборудованием, чтобы обеспечить нашим клиентам качественные и надежные решения.</p>

    <p>За все эти годы мы выполнили более 100 проектов различной сложности и масштабов. Наш опыт и профессионализм помогают нам успешно справляться с любыми вызовами, стоящими перед нами, и обеспечивать наших клиентов качественными решениями.</p>

    <p>Мы гордимся своей репутацией, заслуженной благодаря нашей целеустремленности и стремлению сделать все возможное для того, чтобы обеспечить качественный и безопасный монтаж электрики. Мы гарантируем, что все наши проекты выполнены в срок и соответствуют высоким стандартам качества и безопасности.</p>

    <p>Наша команда стремится к тому, чтобы обеспечить нашим клиентам самые современные и эффективные решения в области электромонтажа.</p>

    <p>Мы приглашаем Вас связаться с нами, чтобы узнать больше о том, как мы можем помочь Вам с Вашими проектами по электромонтажу.</p>
  </div>

export default About
