import React from 'react'
import Head from './Head'
import Advantages from './Advantages'
import Services from './Services'
import Content from './content/Content'
import Footer from '../../elements/Footer'
import { Helmet } from 'react-helmet-async'


const Landing = _ =>

  <div>
    <Helmet prioritizeSeoTags>
      <title>NAN-electro | Ваш надежный партнер в электромонтаже</title>
      <link rel="canonical" href={window.location.href} />
    </Helmet>
    <Head />
    <Services />
    <Advantages />
    <Content type="gallery" />
    <Content type="article" />
    <Footer />
  </div>

export default Landing
