import React from 'react'
import Logo from '../media/Logo'
import Envelope from '../media/Envelope'
import Phone from '../media/Phone'
import {isMobile} from 'react-device-detect';
import './Footer.css'

const Footer = _ =>

  <footer id="contacts">

    <div className={`${isMobile ? 'f-col ai-center' : 'f-row jc-center'}`}>
      <div className="col">
        <Logo isWhite={true} />
      </div>

      <div className="col">
        {/*<Envelope isWhite={true} />*/}
        <div className="label">Почта</div>
        <div>info@nanelectro.ru</div>
      </div>

      <div className="col">
        {/*<Phone isWhite={true} />*/}
        <div className="label">Телефон</div>
        <div>+7 978 019 67 27</div>
      </div>

      <div className="col">

        <div className="label">Мы в соцсетях</div>
        <div className="socLink"><a href="https://t.me/nan_electro">Телеграм</a></div>
        <div className="socLink"><a href="https://vk.com/nan_electro">Вконтакте</a></div>
        <div className="socLink"><a href="https://www.instagram.com/nan_electro">Нельзяграм</a></div>
      </div>
    </div>

    <div className="row">
    </div>

  </footer>

export default Footer
