import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie'
import User from '../../media/User'
import { logout } from '../../../store/actions'
import './UserMenu.css'


const UserMenu = ({ isLanding }) => {
  const [showMenu, toggleMenu] = useState(false)
  const isAuth = useSelector(state => state.user[0].isLogged)
  const [cookies, setCookie, removeCookie] = useCookies(['user'])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const exit = (e) => {
    e.preventDefault()
    removeCookie('user')
    removeCookie('token')
    dispatch(logout())
    navigate("/")
    window.location.reload()
  }

  return (

    <div className={`f-row ai-center buttons ${isLanding ? '' : 'black'}`}>
      <button onClick={() => toggleMenu(!showMenu)}>
        <User isWhite={isLanding ? true : false} />
        <span></span>
      </button>

      {showMenu ?
        <div className={`usermenu ${isLanding ? '' : 'black'}`}>
          {
            isAuth ?
              <div className="f-col ai-center">
                <div className="username">{cookies.user}</div>
                <Link to={"/projects"} onClick={() => toggleMenu(!showMenu)}>
                  Мои проекты
                </Link>
                <input type="button" value="Выйти" onClick={(e) => exit(e)} />
              </div>
              :
              <Link to={"/auth"} onClick={() => toggleMenu(!showMenu)}>
                Вход
              </Link>
          }
        </div>
        :
        null
      }



    </div>

  )
}



export default UserMenu
