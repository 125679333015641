import initialState from '../initialState'

const userReducer = (state = initialState.user, action) => {

  const newArray = [...state];

  switch (action.type) {
    case 'IS_AUTH':

      newArray[0]['isLogged'] = true
      return newArray

    case 'EXIT':

      newArray[0]['isLogged'] = false
      return newArray

    case 'SETTYPE':

      newArray[0]['type'] = action.value
      return newArray

    default:
      return state
  }
}

export default userReducer
