import React,{useState,useEffect} from 'react'
import {SendData} from '../../../functions/SendData'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import {useSelector} from 'react-redux'
import Loading from '../../../elements/Loading'
import EditView from './EditView'
import ItemView from './ItemView'
import Edit from '../../../media/Edit'
import { Helmet } from 'react-helmet-async'

const Article = _ => {

  const isAdmin = useSelector(state => state.user[0].type) == 'admin'
  const [isLoad,setIsLoad] = useState(true)
  const [onEdit,setEdit] = useState(false)
  const [label, setLabel] = useState('')
  const [title, setTitle] = useState('')
  const [date, setDate] = useState('')
  const [description, setDescription] = useState('')
  const [introText, setIntroText] = useState('')
  const [text, setText] = useState('')
  const [published, setPubslished] = useState('')
  const id = useLocation().pathname.split('/')[2];
  const navigate = useNavigate();

  const getArticles = async() => {

    const data = {id: id, type: 'article'}

    SendData('get','content', 'getitem', data).then(result => {
      setLabel(result.data.title)
      setTitle(result.data.title)
      setIntroText(result.data.introtext)
      setPubslished(result.data.published)
      setDate(result.data.date)
      setDescription(result.data.description)
      setText(result.data.text)
      setIsLoad(false)
    })

  }

  const togglePublished = async() => {
    setIsLoad(true)

    const data = {id: id, type: 'articles', published: !published }
    await SendData('post', 'content', 'publication', data).then(result => {
      setPubslished(!published)
      console.log(result.data)
      setIsLoad(false)
    })
  }

  const apply = async() => {

    setIsLoad(true)

    const data = {id: id, type: 'article', title: title, introtext: introText, description: description, text: text}
    const action = (id === 'new') ? 'add' : 'update'

    await SendData('post', 'content', action, data).then(result => {

      if(result.data.result === 'success'){

        setLabel(title)
        setEdit(false)

        if(id === 'new'){
          navigate(`/articles/${result.data.id}`)
        }

      }

      setIsLoad(false)


    })

  }

  useEffect(() => {

    window.scrollTo(0, 0)

    if(id !== 'new'){
      getArticles()
    }

    else{
      setLabel('Новый материал')
      setEdit(true)
      setIsLoad(false)
    }

  }, [])

  return(
    isLoad ?
      <Loading />
      :
      <div className="content">

        <div itemScope itemType="https://schema.org/BlogPosting">
          <Helmet prioritizeSeoTags>
            <title>NAN-electro | {label}</title>
            <link rel="canonical" href={window.location.href} />
            <meta name="headline name" content={label} />
            <meta name="description" content={description} />
            <meta name="datePublished" dateTime={date} content={date} />
          </Helmet>

          <div className="title f-row ai-center">
             <h1>{label}</h1>
             {
               isAdmin ?
                onEdit ?
                  null
                  :
                  <div className="buttons black"><button className="edit" onClick={() => setEdit(true)}><Edit /><span></span></button></div>
                :
                null
             }
          </div>

          {
            isAdmin ?
              onEdit ?
                <div className="buttons black">
                  <button onClick={() => apply()}>Применить<span></span></button>
                  <button onClick={() => togglePublished()}>{published ? 'Снять с публикации' : 'Опубликовать'}<span></span></button>
                  <button onClick={() => {id === 'new' ? navigate(-1) : setEdit(false)}}>Отмена<span></span></button>
                </div>
                :
                null
              :
              null
          }

          {
            onEdit ?
              <EditView
                title={title}
                setTitle={(value) => setTitle(value)}
                description={description}
                setDescription={(value) => setDescription(value)}
                introText={introText}
                setIntroText={(value) => setIntroText(value)}
                text={text}
                setText={(value) => setText(value)}
                />
              :
              <ItemView introText={introText} text={text} />
          }

          <div className="watchMore buttons black">
            <button onClick={() => navigate(-1)}>
              Назад
              <span></span>
            </button>
          </div>

        </div>


      </div>
  )

}

export default Article
