import axios from 'axios'
import { Cookies } from 'react-cookie';
import { Base64 } from 'js-base64';

//const APP_URL = 'https://ck44941.tmweb.ru'
const APP_URL = 'https://api.nanelectro.ru'

export const SendData = async (method, type, action, data) => {

	let headers, url, res

	const cookies = new Cookies();

  const token = JSON.stringify(cookies.get('token'))

	if(token){
		headers = {
			'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
		}
	}

	else{
		headers = {
			'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
		}
	}

	try {

		if(method==='post'){

			//url = `${APP_URL}${type}/${action}`
			url = `${APP_URL}?type=${type}&action=${action}`

			res = await axios({
				method: 'post',
				url: url,
				data: JSON.stringify(data),
				headers: headers,
				/*withCredentials: true,
				credentials: 'include'*/
			})

		}

		else{

			//const dataBody = data ? Base64.encode(JSON.stringify(data)) : ''
			const dataBody = data ? `&data=${Base64.encode(JSON.stringify(data))}` : ''

			//url = `${APP_URL}${type}/${action}/${dataBody}`
			url = `${APP_URL}?type=${type}&action=${action}${dataBody}`

			res = await axios.get(url, {
				headers: headers,
				/*withCredentials: true,
				credentials: 'include'*/
			})

		}

		return res


	} catch (err) {

		return err

	}

}

export const LoadFile = async(data,file) => {

	let res, url

	const formData = new FormData()

	formData.append('file', file)
	formData.append('data', JSON.stringify(data))

	const cookies = new Cookies();

  const token = JSON.stringify(cookies.get('token'))

	const headers = {
		'Authorization': 'Bearer ' + token
	}

	url = `${APP_URL}?type=content&action=addfile`

	res = await axios({
		method: 'post',
		url: url,
		data: formData,
		headers: headers
	})

	return res

}
