import React, { useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'

const ItemView = ({title,setTitle,description,setDescription,introText,setIntroText,text,setText}) => {
  const editorRef = useRef(null)

  return(
    <div>

      <div className="title f-col ai-center">
        <h2>Наименование</h2>
        <textarea value={title} onChange={(e) => setTitle(e.target.value)} />
      </div>

      <div className="title f-col ai-center">
          <h2>Краткое описание (для SEO)</h2>
          <textarea value={description} onChange={(e) => setDescription(e.target.value)} />
        </div>

      <div className="title f-col ai-center">
        <h2>Вводный текст</h2>
        <textarea value={introText} onChange={(e) => setIntroText(e.target.value)} />
      </div>

      <div className="mt-2">
        <Editor
          tinymceScriptSrc={'https://api.nanelectro.ru/files/src/tinymce/tinymce.min.js'}
          onInit={(evt, editor) => editorRef.current = editor}          
          value={text}
          onEditorChange={(newValue, editor) => setText(newValue)}

          init={{
            height: 500,
            menubar: false,
            language: 'ru',
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        />
      </div>

    </div>
  )
}

export default ItemView
