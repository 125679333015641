import React, { useState, useEffect } from 'react'
import { SendData } from '../../../functions/SendData'
import Loading from '../../../elements/Loading'
import { Helmet } from 'react-helmet-async'

const Services = _ => {

  const [data, setData] = useState('')
  const [isLoad, setIsLoad] = useState(true)
  const getContent = async () => {
    SendData('get', 'service', 'getList', null).then(result => {
      setData(result.data)
      setIsLoad(false)
    })
  }

  useEffect(() => {

    getContent()

  }, [])

  return (
    isLoad ?
      <Loading />
      :
      <div className="content">
        <Helmet prioritizeSeoTags>
          <title>NAN-electro | Наши цены</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content="Полный спектр электромонтажных услуг. Доступные цены, гарантия качества работ."></meta>
        </Helmet>
        <h1>Наши цены</h1>
        <table>
          <thead>
            <tr>
              <th>Наименование</th>
              <th>Единица измерения</th>
              <th>Цена</th>
            </tr>
          </thead>
          <tbody>
            {data ?
              data.map((item, index) =>
                <tr key={index}>
                  <td>{item.name}</td>
                  <td className="center">{item.unit_name}</td>
                  <td className="center">{item.price}</td>
                </tr>
              )
              :
              null
            }
          </tbody>
        </table>
      </div>
  )
}

export default Services
