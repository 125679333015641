import { Helmet } from "react-helmet-async"

const NotFound = () => {

    return (
        <div className="content error">
            <Helmet prioritizeSeoTags>
                <title>NAN-electro | Нет страницы</title>
            </Helmet>
            <div className='wrap'>
                <div className='section'>
                    <h1>404</h1>
                    <h3>Нет страницы</h3>
                </div>
            </div>
        </div>
    )

}

export default NotFound