import React from 'react'
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom'
import {useSelector} from 'react-redux'
import Landing from './view/landing/Landing'
import Default from './view/default/Default'
import About from './view/default/about/About'
import Portfolio from './view/default/portfolio/List'
import Case from './view/default/portfolio/Item'
import Services from './view/default/services/Services'
import Contacts from './view/default/contacts/Contacts'
import Articles from './view/default/articles/List'
import Article from './view/default/articles/Item'
import Auth from './view/default/Auth'
import Registration from './view/default/Registration'
import Recovery from './view/default/Recovery'
import Projects from './view/default/projects'
import ProjectItem from './view/default/projects/item'
import NewProject from './view/default/projects/new'
import NotFound from '../components/view/default/NotFound'


const AppRouter = _ => {

  return (

    <Router>
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route element={<Default />}>
          <Route path="/auth" element={<Auth />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:id" element={<ProjectItem />} />
          <Route path="/projects/new" element={<NewProject />} />
          <Route path="/signup" element={<Registration />} />
          <Route path="/recovery" element={<Recovery />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/cases" element={<Portfolio />} />
          <Route path="/cases/:id" element={<Case />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/articles/:id" element={<Article />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="*" element={<NotFound />} status={404}/>
        </Route>
      </Routes>
    </Router>

  )

}

export default AppRouter;
