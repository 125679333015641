const Voltmeter = ({isWhite=false}) => {

  const color = isWhite ? "#fff" : "#000000"
  const lineColor = isWhite ? "rgb(52, 58, 64)" : "rgb(242,242,242)"

  return (
    <div>
      <svg fill="none" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 295.239 295.239" xmlSpace="preserve">
        <path fill={color} d="M161.904,33.333H28.571V100h133.333V33.333z M152.381,90.476L152.381,90.476H38.095V42.857h114.286V90.476z"/>
        <path fill={color} d="M95.238,133.333c-28.881,0-52.381,23.5-52.381,52.381s23.5,52.381,52.381,52.381c28.881,0,52.381-23.5,52.381-52.381 S124.119,133.333,95.238,133.333z M56.338,203.595c-2.514-5.452-3.957-11.495-3.957-17.881c0-23.633,19.224-42.857,42.857-42.857 c6.386,0,12.429,1.443,17.881,3.957L56.338,203.595z M61.433,211.967l60.052-60.052c2.824,2.195,5.357,4.729,7.552,7.552 l-60.052,60.052C66.167,217.324,63.629,214.786,61.433,211.967z M138.095,185.714c0,23.634-19.224,42.857-42.857,42.857 c-6.386,0-12.429-1.443-17.881-3.957l56.781-56.781C136.652,173.285,138.095,179.328,138.095,185.714z"/>
        <rect fill={color} x="90.476" y="119.048" width="9.524" height="9.524"/>
        <rect fill={color} x="90.476" y="242.857" width="9.524" height="9.524"/>
        <rect fill={color} x="152.381" y="180.952" width="9.524" height="9.524"/>
        <rect fill={color} x="28.571" y="180.952" width="9.524" height="9.524"/>

        <rect fill={color} x="133.333" y="136.123" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 336.7582 146.2245)" width="9.524" height="13.467"/>

        <rect fill={color} x="45.647" y="223.809" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 -72.2046 427.2335)" width="13.467" height="9.524"/>

        <rect fill={color} x="133.333" y="221.839" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 74.1179 487.8445)" width="9.524" height="13.467"/>

        <rect fill={color} x="45.647" y="138.095" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 190.4346 206.8335)" width="13.467" height="9.524"/>
        <rect fill={color} x="47.619" y="52.381" width="9.524" height="28.571"/>
        <rect fill={color} x="66.667" y="52.381" width="9.524" height="28.571"/>
        <rect fill={color} x="133.333" y="52.381" width="9.524" height="9.524"/>
        <rect fill={color} x="85.714" y="52.381" width="9.524" height="9.524"/>
        <rect fill={color} x="133.333" y="71.429" width="9.524" height="9.524"/>
        <path fill={color} d="M295.238,66.667h-4.762V33.333h-9.524V0h-9.524v33.333h-9.524v33.333h-4.762v9.524h4.762v38.095 c0,6.2,3.995,11.438,9.524,13.41v91.352c0,31.51-25.633,57.143-57.143,57.143h-23.81v-28.571H200 c18.376,0,33.333-14.957,33.333-33.333v-86.59c5.529-1.971,9.524-7.21,9.524-13.41V76.19h4.762v-9.524h-4.762V33.333h-9.524V0 h-9.524v33.333h-9.524v33.333h-4.762v9.524h4.762v38.095c0,6.2,3.995,11.438,9.524,13.41v86.59 c0,13.129-10.681,23.81-23.81,23.81h-9.524V14.286C190.476,6.41,184.067,0,176.19,0H14.286C6.41,0,0,6.41,0,14.286v266.667 c0,7.876,6.41,14.286,14.286,14.286H176.19c6.2,0,11.438-3.995,13.41-9.524h24.686c36.762,0,66.667-29.905,66.667-66.667v-91.352 c5.529-1.971,9.524-7.21,9.524-13.41V76.19h4.762L295.238,66.667L295.238,66.667z M223.81,42.857h9.524v23.81h-9.524V42.857z M223.809,76.19h9.524v38.095c0,2.629-2.133,4.762-4.762,4.762c-2.629,0-4.762-2.133-4.762-4.762V76.19z M180.952,280.952 c0,2.629-2.133,4.762-4.762,4.762H14.286c-2.629,0-4.762-2.133-4.762-4.762V14.286c0-2.629,2.133-4.762,4.762-4.762H176.19 c2.629,0,4.762,2.133,4.762,4.762V280.952z M280.952,114.286c0,2.629-2.133,4.762-4.762,4.762c-2.629,0-4.762-2.133-4.762-4.762 V76.19h9.524V114.286z M280.953,66.667h-9.524v-23.81h9.524V66.667z"/>
        <rect fill={color} x="19.048" y="261.905" width="19.048" height="9.524"/>
        <rect fill={color} x="152.381" y="261.905" width="19.048" height="9.524"/>
      </svg>
    </div>
  )
}


export default Voltmeter
