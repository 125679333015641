import { isMobile } from "react-device-detect"
import ItemCalculation from "./itemCalculation"

export default function ItemData({data}){
    const getWallName = value => {
        switch (value) {
            case '1':
                return 'Газоблок'
            case '2':
                return 'Кирпич'
            case '3':
                return 'Бетон'
            default:
                break
        }
    }    

    return (
        data ?
            <div className=''>
                <div className={'f-row ai-center jc-sb'}>

                    <h1>{data.name}</h1>

                </div>

                <div className={`${isMobile ? 'f-col' : 'f-row'} ai-center jc-sb`}>

                    <h2 className='f-row ai-center'>Площадь: {JSON.parse(data.params).area} м<sup>2</sup></h2>
                    <h2>Количество помещений: {JSON.parse(data.params).rooms}</h2>
                    <h2>Высота потолка: {JSON.parse(data.params).heigth} мм</h2>
                    <h2>Материал стен: {getWallName(JSON.parse(data.params).walls)}</h2>

                </div>



                <ItemCalculation name="Работы" list={data.calculate.services} total={data.calculate.servicePrice} />

                <ItemCalculation name="Материалы" list={data.calculate.materials} total={data.calculate.materialPrice} />

                <div className='result'><h2 >Общая стоимость: {Number(data.calculate.materialPrice) + Number(data.calculate.servicePrice)} рублей</h2></div>

            </div>
            :
            null
    )
}