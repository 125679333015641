import React,{useState,useEffect} from 'react'
import {SendData} from '../../../functions/SendData'
import Loading from '../../../elements/Loading'
import ListItem from './ListItem'
import {useSelector} from 'react-redux'
import Add from '../../../media/Add'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const Articles = _ => {
  const isAdmin = useSelector(state => state.user[0].type) == 'admin'
  const [data, setData] = useState('')
  const [isLoad,setIsLoad] = useState(true)
  const linkPath = '/articles'
  const getContent = async() => {
    SendData('get','content','getList', {type: 'article'}).then(result => {
      setData(result.data)
      setIsLoad(false)
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getContent()

  }, [])

  return(
    isLoad ?
      <Loading />
      :
      <div className="content">

        <Helmet prioritizeSeoTags>
          <title>NAN-electro | Советы</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content="Полезные советы и рекомендации по выполнению электромонтажных работ. Узнайте секреты профессионалов и правильные способы монтажа электрических систем."></meta>
        </Helmet>

        <div itemScope itemType="https://schema.org/Blog">

          <div className="title f-row ai-center jc-sb">
             <h1>Советы</h1>
             {
               isAdmin ?
                 <Link to={`/articles/new`}>
                   <Add />
                 </Link>
                :
                null
             }
          </div>

          <div className="articles">
            <ul>
              {
                data.map((item,index) =>
                  <ListItem key={index} data={item} />
                )
              }
            </ul>
          </div>

        </div>


      </div>
  )
}



export default Articles
