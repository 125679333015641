import { Link } from "react-router-dom"

export default function ProjectList({data}){

    return(
        <table className="default">

                <thead>
                    <tr>
                        <th >ID</th>
                        <th >Название</th>
                        <th >Дата создания</th>
                        <th ></th>
                    </tr>
                </thead>

                <tbody>
                    {
                        data.map((item, index) =>
                            <tr key={index}>
                                <td >{item.id}</td>
                                <td >{item.name}</td>
                                <td >{item.created}</td>
                                <td ><Link to={`/projects/${item.id}`}>перейти</Link></td>
                            </tr>
                        )
                    }
                </tbody>

            </table>

    )

}