import React, {useState} from 'react'
import FsLightbox from "fslightbox-react"

const FILE_URL = 'https://api.nanelectro.ru/files/images/'

const ItemView = ({data}) => {

  const [toggler, setToggler] = useState(false)

  const images = data ?
    JSON.parse(data).data.filter(item => item.visible).map((result) =>
      `${FILE_URL}${result.image}`
    )
    :
    null

  return(
    <div className="gallery">

      <FsLightbox toggler={toggler} sources={images} type="image" />
      {JSON.parse(data).data.map((item,index) =>
        item.visible ?
          <div key={index} className="image">
            <button onClick={() => setToggler(!toggler)}>
              <img src={`${FILE_URL}${item.image}`} alt={data.title} />
            </button>
          </div>
          :
          null
      )}

    </div>
  )

}

export default ItemView
