import React from 'react'
import Quality from '../../media/Quality'
import House from '../../media/House'
import Network from '../../media/Network'
import Safety from '../../media/Safety'
import Voltmeter from '../../media/Voltmeter'
import Calculate from '../../media/Calculate'
import Idea from '../../media/Idea'
import SmartHome from '../../media/SmartHome'
import './Advantages.css'

const Advantages = _ =>

  <div className="advantages">

    <h1>ПОЧЕМУ МЫ?</h1>

    <div className="wrap">

      <div className="row">

        <div className="item">
          <Idea />
          <div className="header">Мы подключим вас к лучшим<br />решениям в электромонтаже</div>
          <div className="descr">Мы всегда следуем за новейшими технологиями и способами работы, чтобы гарантировать нашим клиентам лучшее качество услуг.</div>
        </div>

        <div className="item">
          <Quality />
          <div className="header">Безопасность и надежность -<br />наши приоритеты</div>
          <div className="descr">Наш профессиональный подход к работе и использование только качественных материалов обеспечивает надежность и долговечность работы.</div>
        </div>
        <div className="item">
          <Network />
          <div className="header">Стабильность в электричестве<br />через профессиональный монтаж</div>
          <div className="descr">Наш опытный персонал гарантирует, что ваша электрическая система будет работать наилучшим образом после нашего вмешательства. Мы предлагаем профессиональный монтаж, который обеспечивает стабильность и безопасность.</div>
        </div>

      </div>

      <div className="row">

        <div className="item">
          <SmartHome />
          <div className="header">Свой дом. Своя энергия.<br />Надежная электропроводка.</div>
          <div className="descr">Наш подход к работе является индивидуальным и ориентированным на ваши уникальные потребности. Мы всегда готовы предложить наилучшее решение для вашего дома и гарантируем надежный электромонтаж.</div>
        </div>

        <div className="item">
          <Voltmeter />
          <div className="header">Экспертный подход к работе<br />с электрическими системами</div>
          <div className="descr">Мы всегда следуем передовым технологиям и используем наиболее эффективные методы, чтобы предоставить вам наилучшие решения для оптимизации работы вашей электрической системы.</div>
        </div>
        <div className="item">
          <Calculate />
          <div className="header">Превосходство качества<br />по разумной стоимости</div>
          <div className="descr">Мы стремимся к тому, чтобы наши услуги были доступны для каждого клиента. Мы гарантируем, что вы получаете высокое качество работы по адекватной цене.</div>
        </div>
      </div>


    </div>




  </div>

export default Advantages
