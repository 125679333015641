const Calculate = ({isWhite=false}) => {

  const color = isWhite ? "#fff" : "#000000"
  const lineColor = isWhite ? "rgb(52, 58, 64)" : "rgb(242,242,242)"

  return (
    <div>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" xmlSpace="preserve">
      <path fill={color} d="M14.5,22h-2c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2c0.276,0,0.5,0.224,0.5,0.5 S14.776,22,14.5,22z M10,21.5c0-0.276-0.224-0.5-0.5-0.5h-2C7.224,21,7,21.224,7,21.5S7.224,22,7.5,22h2C9.776,22,10,21.776,10,21.5 z M15,16.5c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h2C14.776,17,15,16.776,15,16.5z M10,16.5 c0-0.276-0.224-0.5-0.5-0.5h-2C7.224,16,7,16.224,7,16.5S7.224,17,7.5,17h2C9.776,17,10,16.776,10,16.5z M25,21.5 c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h2C24.776,22,25,21.776,25,21.5z M20,21.5 c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h2C19.776,22,20,21.776,20,21.5z M15,26.5
c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h2C14.776,27,15,26.776,15,26.5z M10,26.5 c0-0.276-0.224-0.5-0.5-0.5h-2C7.224,26,7,26.224,7,26.5S7.224,27,7.5,27h2C9.776,27,10,26.776,10,26.5z M25,26.5
c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h2C24.776,27,25,26.776,25,26.5z M20,26.5 c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h2C19.776,27,20,26.776,20,26.5z M25,16.5
c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h2C24.776,17,25,16.776,25,16.5z M20,16.5 c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h2C19.776,17,20,16.776,20,16.5z M30,1v30
c0,0.552-0.448,1-1,1H3c-0.552,0-1-0.448-1-1V1c0-0.552,0.448-1,1-1h26C29.552,0,30,0.448,30,1z M29,1H3v30h26V1z M7,11.5v-6 C7,5.224,7.224,5,7.5,5h17C24.776,5,25,5.224,25,5.5v6c0,0.276-0.224,0.5-0.5,0.5h-17C7.224,12,7,11.776,7,11.5z M8,11h16V6H8V11z"
/>
      </svg>
    </div>
  )
}


export default Calculate
